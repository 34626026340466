import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export function JprdyCard () {
  return (
      <Card>
        <CardMedia
          component="img"
          height="200"
          image="/img/jprdy.png"
          alt="jprdy"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            JPRDY
          </Typography>
          <Typography>A trivia game you can play with your friends</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => window.location.href = '/jprdy'}>View</Button>
        </CardActions>
      </Card>
  )
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export function TetrisCard () {
  return (
      <Card>
        <CardMedia
          component="img"
          height="200"
          image="/img/tetris.png"
          alt="tetris"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Tetris
          </Typography>
          <Typography>Classic Tetris in 3D</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => window.location.href = '/tetris'}>View</Button>
        </CardActions>
      </Card>
  )
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export function RubiksCard () {
  return (
      <Card>
        <CardMedia
          component="img"
          height="200"
          image="/img/rubiks.png"
          alt="rubiks"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Rubiks
          </Typography>
          <Typography>A 3D Rubiks Cube</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => window.location.href = '/rubiks'}>View</Button>
        </CardActions>
      </Card>
  )
}

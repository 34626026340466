import { useEffect, useRef, useState } from 'react';
import { getRandomNumber } from '../utils';
const DISTANCE = 110;
export function Screen () {
  const alpha = useRef(getRandomNumber({ max: 0.2, min: 0.05 }));
  const tilt = useRef(getRandomNumber({ max: 30 }));
  const rtl = useRef(Math.floor(getRandomNumber({ max: 2, min: 0 })));
  const border = useRef(getRandomNumber({ max: 5, min: 1 }));
  const skew = useRef(getRandomNumber({ max: 10, min: 5 }));
  const skewDirection = useRef(Math.floor(getRandomNumber({ max: 2, min: 0 })));
  const x = useRef(rtl.current ? -1 * DISTANCE : DISTANCE);
  const requestRef: any = useRef();
  const speed = useRef(getRandomNumber({ max: .5, min: 0.1 }));
  const [style, setStyle]: [any, any] = useState({
    position: 'fixed' as 'fixed',
    border: `${border.current}px solid rgba(255,255,255, ${alpha.current})`,
    width: '100vw',
    height: '100vh',
    top: 0,
    transform: `rotate(${tilt.current}deg) skew(${skew.current}deg)`,
    zIndex: -1,
  });
  useEffect(() => {
    function step () {
      if (rtl.current) {
        x.current = x.current + speed.current;
        if (x.current >= DISTANCE) {
          x.current = -1 * DISTANCE;
        }
      } else {
        x.current = x.current - speed.current;
        if (x.current <= -1 * DISTANCE) {
          x.current = DISTANCE;
        }
      }
      if (skew.current >= 10) {
        skewDirection.current = 0;
      }
      if (skew.current <= 5) {
        skewDirection.current = 1;
      }
      skew.current = skew.current + (skewDirection ? -1 : 1);
      setStyle({
        ...style,
        transform: `rotate(${tilt.current}deg) skew(${skew.current}deg)`,
        left: `${x.current}vw`,
      })
      setTimeout(() => {
        requestRef.current = window.requestAnimationFrame(step);
      }, 40);
    }
    requestRef.current = window.requestAnimationFrame(step);
    return () => window.cancelAnimationFrame(requestRef.current);
  // eslint-disable-next-line
  }, [])
  return (
    <div style={style}></div>
  )
}

import { useState } from 'react';
import { Screen } from './Screen';
export function Background (props: {
  n?: number;
}) {
  const [screens] = useState(new Array(props.n ?? 3).fill(1));
  return (
    <>
      {
        screens.map((i, idx) => (<div key={idx}><Screen /></div>))
      }
    </>
  );
}

import React/*, { useRef, useState }*/ from 'react';
import { Box, Typography } from '@mui/material';
import { Background, LinkList } from './components';
// import { Parallax, ParallaxLayer } from '@react-spring/parallax';
// import { animated, config, useSpring } from '@react-spring/web';
// import { ReactComponent as IconArrowDown } from './icons/arrow_downward.svg';

function App() {
  // const [flip, set] = useState(false);
  // const parallaxRef = useRef<any>();
  // const arrowStyles: any = useSpring({
  //   from: {
  //     fill: 'rgba(255,255,255,1)',
  //     bottom: '15px',
  //     transform: 'scale(1.2)',
  //   },
  //   fill: 'rgba(255,255,255,0.75)',
  //   transform: 'scale(1)',
  //   bottom: '50px',
  //   reset: true,
  //   reverse: flip,
  //   delay: 200,
  //   config: config.slow,
  //   onRest: () => set(!flip),
  // });
  // const Arrow = animated(IconArrowDown);
  // return (
  //   <Parallax pages={2} style={{ top: '0', left: '0' }} ref={parallaxRef}>
  //     <ParallaxLayer
  //       offset={0}
  //       speed={2.5}
  //       style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <h1>taylordeckard.me</h1>
  //       <div onClick={() => parallaxRef.current.scrollTo(1)}>
  //         <Arrow style={{
  //           ...arrowStyles,
  //           width: '100px',
  //           position: 'absolute',
  //           left: 'calc(50vw - 50px)',
  //           cursor: 'pointer',
  //         }}/>
  //       </div>
  //     </ParallaxLayer>

  //     <ParallaxLayer
  //       offset={1}
  //       speed={0.1}
  //       style={{
  //         background: 'linear-gradient(0deg, transparent 0%, #002255 50%, transparent 100%)',
  //       }} />

  //     <ParallaxLayer
  //       offset={1}
  //       speed={2}
  //       style={{
  //         background: 'linear-gradient(0deg, #004333, transparent)',
  //       }} />

  //     <ParallaxLayer
  //       offset={1}
  //       speed={1}
  //     >
  //       <Background n={6}/>
  //       <Box margin={4}>
  //         <LinkList/>
  //       </Box>
  //     </ParallaxLayer>
  //   </Parallax>
  // );
  return (
    <>
      <Typography variant="h4" margin={4}>taylordeckard.me</Typography>
      <Background n={6}/>
      <Box margin={4}>
        <LinkList/>
      </Box>
    </>
  )
}

export default App;

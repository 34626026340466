import { Grid } from '@mui/material';
import { BlogCard } from './BlogCard';
import { JprdyCard } from './JprdyCard';
import { RubiksCard } from './RubiksCard';
import { TetrisCard } from './TetrisCard';

export function LinkList () {
  const cards = [
    <BlogCard/>,
    <JprdyCard/>,
    <RubiksCard/>,
    <TetrisCard/>,
  ];
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {
        cards.map((card, idx) =>(
          <Grid key={`card-${idx}`} item xs={12} sm={6} md={4}>
            {card}
          </Grid>
        ))
      }
    </Grid>
  );
}
